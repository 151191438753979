
import { IRootState } from '../context/Store/StoreTypes';
import { DialogReducer } from './DialogReducer';
import { EditorReducer } from './EditorReducer';
import { PropertyGridReducer } from './PropertyGridReducer';

function combineReducers(reducers) {
    return (state: any, action: any) => {
        const newState: any = {};
        Object.keys(reducers).forEach((key) => {
            const dataKey = key.replace("Reducer", "State");
            newState[dataKey] = reducers[key](
                action.type === "RESET_TO_INITIAL_STATE" ? undefined : state[dataKey],
                action
            );
        });
        return newState;
    };
}





export const rootReducer = (state: IRootState, action) => {
    return combineReducers({
        dialogState: DialogReducer,
        editorState: EditorReducer,
        propertyGridState: PropertyGridReducer,
    })(state, action);
}