import { useMemo } from 'react';
import { TranslationContext } from './TranslationTypes';
import { useGetErrorMessages, useGetTranslations } from '../../../../api/DesignApp/DesignAppHooks';

export const TranslationProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { data: translations } = useGetTranslations();
  const { data: errorMessages } = useGetErrorMessages();

  const contextValue = useMemo(
    () => ({
      getTranslatedString: (translationToken: string): string => {
        return translations?.[translationToken] || errorMessages?.[translationToken] || `_T(${translationToken})`;
      },
      getTranslatedErrorMessage: (translationToken: string): string => {
        return errorMessages?.[translationToken] || `_T(${translationToken})`;
      },
    }),
    [translations, errorMessages]
  );

  return <TranslationContext.Provider value={contextValue}>{children}</TranslationContext.Provider>;
};
