import { Box, Button, Checkbox, FormControlLabel, TextField, Tooltip, Typography } from '@mui/material';
import { useToaster } from '../../context/ToasterContext/ToasterContext';
import { ProgressIndicator } from '../../components/ProgressIndicator/ProgressIndicator';
import { useConfigState, useVrsTranslationState } from '../../context/AppContext/AppContext';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { IAccountSettings } from '../../interfaces/Design/IAccountSettings';
import { IUserSettings } from '../../interfaces/Design/IUserSettings';
import {
  useGetAccountSettings,
  useGetUserSettings,
  useUpdateAccountSettings,
  useUpdateUserSettings,
} from '../../api/Design/DesignHooks';
import PrinterModelSelector from './PrinterModelSelector';

import { Settings as SettingsIcon } from '@mui/icons-material';
import { useAppUserState } from '../../context/AppContext/AppContext';

import { useSetTitleAction } from '../../actions/useActions/useSetTitleAction/useSetTitleAction';
import { NotAuthorised } from '../designeditor/components/NotAuthorised/NotAuthorised';
import { isExternalNormalUserOrAdminOrMaintenance } from '../../utilities/utils';
import Utils from '../../utilities/utils';

const ManageSettingsPage = () => {
  const toastr = useToaster();
  const { _T } = useVrsTranslationState();
  const setTitle = useSetTitleAction();
  const { userProfileLarge } = useAppUserState();
  const configState = useConfigState();

  const betaVersion = !!userProfileLarge?.ListMember?.find(
    (el) => (el.Id === 'config_import' || el.Id === 'early_accessor') && el.MemberType === 'admin'
  );

  const isCurrentSiteExternal = configState.appDataInitialization.firstSiteData.isExternal;
  const externalCustomerSite = useMemo(() => (Utils.isDev() && isCurrentSiteExternal && isExternalNormalUserOrAdminOrMaintenance()), [isCurrentSiteExternal]);

  const { data: accountSettings, isLoading: accountSettingsLoading } = useGetAccountSettings();
  const { data: userSettings, isLoading: userSettingsLoading } = useGetUserSettings();

  const [savingInitiated, setSavingInitiated] = useState(false);

  const accountSettingsMutation = useUpdateAccountSettings();

  const userSettingsMutation = useUpdateUserSettings();

  const [localAccountSettings, setLocalAccountSettings] = useState<IAccountSettings>({} as IAccountSettings);
  const [localUserSettings, setLocalUserSettings] = useState<IUserSettings>({} as IUserSettings);

  useEffect(() => {
    if (accountSettings && !accountSettingsLoading) {
      setLocalAccountSettings(accountSettings);
    }
  }, [accountSettings, accountSettingsLoading]);

  useEffect(() => {
    if (userSettings && !userSettingsLoading) {
      setLocalUserSettings(userSettings);
    }
  }, [userSettings, userSettingsLoading]);

  useEffect(() => {
    if (savingInitiated && !accountSettingsLoading && !userSettingsLoading && !accountSettingsMutation.isLoading
      && !userSettingsMutation.isLoading && localUserSettings === userSettings
      && localAccountSettings === accountSettings) {
      setSavingInitiated(false);
      toastr.success("Settings saved successfully.");
    }
  }, [savingInitiated, localUserSettings, localAccountSettings, accountSettings, userSettings,
    userSettingsLoading,
    accountSettingsMutation.isLoading,
    userSettingsMutation.isLoading, toastr, accountSettingsLoading, userSettingsLoading]);

  const handleSave = () => {
    setSavingInitiated(true);
    if (isAccountSettingsDirty()) {
      accountSettingsMutation.mutate(localAccountSettings);
    }
    if (isUserSettingsDirty()) {
      userSettingsMutation.mutate(localUserSettings);
    }
  };

  const handlePrinterModelChange = (printerModel: string) => {
    setLocalUserSettings((prevState) => ({ ...prevState, DefaultPrinterName: printerModel }));
  };

  const handlePrinterFormatChange = (printerFormat: string) => {
    setLocalUserSettings((prevState) => ({ ...prevState, DefaultFormat: printerFormat }));
  };

  const isUserSettingsDirty = useCallback(() => {
    const userSettingsDirty =
      localUserSettings.AllowOffImageFields !== userSettings?.AllowOffImageFields ||
      localUserSettings.DisplayGrid !== userSettings?.DisplayGrid ||
      localUserSettings.GridSize !== userSettings?.GridSize ||
      localUserSettings.SnapToGrid !== userSettings?.SnapToGrid ||
      localUserSettings.SnapToOtherFields !== userSettings?.SnapToOtherFields ||
      localUserSettings.DefaultPrinterName !== userSettings?.DefaultPrinterName ||
      localUserSettings.DefaultFormat !== userSettings?.DefaultFormat;

    return userSettingsDirty;
  }, [localUserSettings, userSettings]);

  const isAccountSettingsDirty = useCallback(() => {
    const accountSettingsDirty =
      localAccountSettings.EquipmentReference !== accountSettings?.EquipmentReference ||
      localAccountSettings.FactoryReference !== accountSettings?.FactoryReference ||
      localAccountSettings.LineReference !== accountSettings?.LineReference;

    return accountSettingsDirty;
  }, [localAccountSettings, accountSettings]);


  useEffect(() => {
    setTitle(
      _T('Design Settings'),
      <SettingsIcon />,
      {
        ignoreItself: true,
      },
      true
    );
  }, [setTitle, _T]);


  return (
    betaVersion || externalCustomerSite ? <Box justifyContent="center" padding={2} sx={{ maxWidth: '600px' }}>

      {!userSettingsLoading && localUserSettings && Object.keys(localUserSettings).length > 0 && (
        <Box>
          <Box>
            <Typography variant="h6">
              {_T("Default Printer")}
            </Typography>
          </Box>
          <Box display={'flex'} flexDirection={'column'} mx={{ border: '1px solid #d8d8d8', borderRadius: '5px', padding: '5px 10px' }}>
            <PrinterModelSelector
              handlePrinterModelChange={handlePrinterModelChange}
              handlePrinterFormatChange={handlePrinterFormatChange}
              direction="column"
            />
          </Box>
          <Box mx={{ marginTop: '5px' }}>
            <Typography variant="h6">
              {_T("Template Settings")}
            </Typography>
          </Box>
          <Box display={'flex'} flexDirection={'column'} mx={{ border: '1px solid #d8d8d8', borderRadius: '5px', padding: '5px 10px', marginTop: '2px' }}>
            <FormControlLabel
              control={
                <Checkbox
                  id="allow-off-image-fields-checkbox"
                  checked={localUserSettings.AllowOffImageFields}
                  onChange={(e) =>
                    setLocalUserSettings({ ...localUserSettings, AllowOffImageFields: e.target.checked })
                  }
                />
              }
              label={_T('Allow Off Image Fields')}
            />
            <FormControlLabel
              control={
                <Checkbox
                  id="display-grid-checkbox"
                  checked={localUserSettings.DisplayGrid}
                  onChange={(e) => setLocalUserSettings({ ...localUserSettings, DisplayGrid: e.target.checked })}
                />
              }
              label={_T('Display Grid')}
            />
            <FormControlLabel
              control={
                <Checkbox
                  id="snap-to-grid-checkbox"
                  checked={localUserSettings.SnapToGrid}
                  onChange={(e) => setLocalUserSettings({ ...localUserSettings, SnapToGrid: e.target.checked })}
                />
              }
              label={_T('Snap To Grid')}
            />
            <FormControlLabel
              control={
                <Checkbox
                  id="snap-to-other-fields-checkbox"
                  checked={localUserSettings.SnapToOtherFields}
                  onChange={(e) =>
                    setLocalUserSettings({ ...localUserSettings, SnapToOtherFields: e.target.checked })
                  }
                />
              }
              label={_T('Snap To Other Fields')}
            />
            <TextField
              id="grid-size-textfield"
              label={_T('Grid Size')}
              type="number"
              value={localUserSettings.GridSize}
              onChange={(e) => setLocalUserSettings({ ...localUserSettings, GridSize: parseInt(e.target.value) })}
              fullWidth
              size="small"
              margin="normal"
            />
          </Box>


        </Box>
      )}
      {!accountSettingsLoading && localAccountSettings && Object.keys(localAccountSettings).length > 0 && (
        <>
          <Box mx={{ marginTop: '5px' }}>
            <Typography variant="h6">
              {_T("Reference Values")}
            </Typography>
          </Box>

          <Box display={'flex'} flexDirection={'column'} mx={{ border: '1px solid #d8d8d8', borderRadius: '5px', padding: '5px 10px', marginTop: '2px' }}>
            <TextField
              id="equipment-reference-textfield"
              label={_T('Equipment Reference')}
              value={localAccountSettings.EquipmentReference}
              onChange={(e) =>
                setLocalAccountSettings({ ...localAccountSettings, EquipmentReference: e.target.value })
              }
              fullWidth
              size="small"
              margin="normal"
            />
            <TextField
              id="line-reference-textfield"
              label={_T('Line Reference')}
              value={localAccountSettings.LineReference}
              onChange={(e) => setLocalAccountSettings({ ...localAccountSettings, LineReference: e.target.value })}
              fullWidth
              size="small"
              margin="normal"
            />
            <TextField
              id="factory-reference-textfield"
              label={_T('Factory Reference')}
              value={localAccountSettings.FactoryReference}
              onChange={(e) => setLocalAccountSettings({ ...localAccountSettings, FactoryReference: e.target.value })}
              fullWidth
              size="small"
              margin="normal"
            />
          </Box>
        </>
      )}
      {!userSettingsLoading && !accountSettingsLoading && <Box display={'flex'} justifyContent="center" paddingTop={1.5}>
        <Tooltip title={_T('Save Settings Dialog')} placement="top">
          <Button
            id="save-settings-btn"
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={!isUserSettingsDirty() && !isAccountSettingsDirty()}>
            {_T('Save')}
          </Button>
        </Tooltip>

      </Box>
      }
      {(userSettingsLoading || accountSettingsLoading) && <ProgressIndicator />
      }
    </Box> : <NotAuthorised />
  );
};

export default ManageSettingsPage;

