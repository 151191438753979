import { useCallback } from "react";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { useCiffEditorContext } from "../context/CiffEditor/CiffEditorHooks";
import {useVrsTranslationState} from "../../../context/AppContext/AppContext";


export function useCiffEditorPdfExport() {
  const {
    mxGraphInstance,
    isBlocked,
    ciffDescriptor,
    subImageHeader,
    actualSizeZoomFactor,
    setShowBackdrop,
    blockCiffEditor,
    customZoomToActual,
    showMessageBox,
    toaster
  } = useCiffEditorContext();

  const { _T } = useVrsTranslationState();


  return useCallback(async () => {
    if (isBlocked.current) {
      return;
    }


    try {
      blockCiffEditor(true);
      setShowBackdrop(true);

      // Get the CIFF container
      const ciffContainer = document.getElementById('ciffDocument') ||
        document.getElementById('workspace') ||
        document.querySelector('.workspace__grid');

      if (!ciffContainer) {
        showMessageBox(_T('Error'), _T('Could not find editor content to export'), false);
        blockCiffEditor(false);
        setShowBackdrop(false);
        return;
      }

      // Store original states
      const originalZoom = mxGraphInstance.view.scale;
      const originalScroll = {
        top: ciffContainer.scrollTop,
        left: ciffContainer.scrollLeft
      };

      // Temporarily hide the grid
      const gridCanvas = document.querySelector('canvas[style*="z-index: -1"]') as HTMLElement;
      const originalGridVisibility = gridCanvas ? gridCanvas.style.display : null;
      if (gridCanvas) {
        gridCanvas.style.display = 'none';
      }

      // Apply zoom to actual size
      await customZoomToActual(subImageHeader, actualSizeZoomFactor);

      // Calculate PDF dimensions
      const labelWidth = subImageHeader.ImageWidth;
      const labelHeight = subImageHeader.ImageHeight;
      const aspectRatio = labelWidth / labelHeight;

      // Determine orientation and page size
      const orientation = aspectRatio > 1 ? 'landscape' : 'portrait';
      const pdf = new jsPDF(orientation, 'mm', 'a4');

      // Get page dimensions
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      // Create a canvas with higher quality
      const canvas = await html2canvas(ciffContainer, {
        scale: 2,
        backgroundColor: '#FFFFFF',
        logging: false,
        allowTaint: true,
        useCORS: true
      });

      // Calculate proper margins and scaling
      const margin = 10;
      const availableWidth = pdfWidth - (margin * 2);
      const availableHeight = pdfHeight - (margin * 2);

      const scaleWidth = availableWidth / canvas.width;
      const scaleHeight = availableHeight / canvas.height;
      const scale = Math.min(scaleWidth, scaleHeight);

      const scaledWidth = canvas.width * scale;
      const scaledHeight = canvas.height * scale;

      const x = margin + (availableWidth - scaledWidth) / 2;
      const y = margin + (availableHeight - scaledHeight) / 2;

      // Add image to PDF
      pdf.addImage(
        canvas.toDataURL('image/png'),
        'PNG',
        x,
        y,
        scaledWidth,
        scaledHeight
      );

      // Get the filename from the descriptor, replace # with _
      const fileName = ciffDescriptor && ciffDescriptor.EditFile ?
        `${ciffDescriptor.EditFile.replace(/#/g, '_')}.pdf` : 'design-export.pdf';

      // Save the PDF
      pdf.save(fileName);

      // Restore original states
      if (gridCanvas && originalGridVisibility !== null) {
        gridCanvas.style.display = originalGridVisibility;
      }

      mxGraphInstance.zoomTo(originalZoom, false);
      ciffContainer.scrollTop = originalScroll.top;
      ciffContainer.scrollLeft = originalScroll.left;

      toaster.success(`${_T('PDF exported successfully:')} ${fileName}`);
    } catch (error) {
      console.error('PDF export error:', error);
      showMessageBox(_T('Error'), _T('Failed to export PDF. Please try again.'), false);
    } finally {
      setShowBackdrop(false);
      blockCiffEditor(false);
    }
  }, [
    mxGraphInstance, isBlocked, ciffDescriptor,
    subImageHeader, actualSizeZoomFactor, setShowBackdrop,
    blockCiffEditor, customZoomToActual, showMessageBox, toaster, _T
  ]);
}
