import { StringIndexedObject } from "../../interfaces/Design/IStringIndexedObject";
import { dedupeArray } from "../../pages/designeditor/utilities/UtilityLibrary";
import DesignApi from "../prodDesignApi";

const applicationTranslationTokens = [
    //Default user level warnings
    'defaultUserLevelWarningTitle',
    'CE_CirrusUnsupportedFeature',
    'CE_CirrusUserLevelPriceField',
    'CE_CirrusUserLevelEAN128Field',
    'CE_CirrusUserLevelLogoField',
    'CE_CirrusUserLevelShapeField',
    'CE_CirrusUserLevelTextBlockField',
    'CE_CirrusUserLevelLinkText',

    // Client error messages
    'CE_GetPropertiesFromGridFailed',
    'CE_UnknownError',
    'CE_FailureWithStatusCode',
    'CE_AppHandledUndoError',
    'CE_GenericUUndoError',
    'CE_AppHandledRedoError',
    'CE_GenericURedoError',
    'CE_LoadUpdatedPropertiesError',
    'CE_UpdatePropertiesError',
    'CE_ErrorLoadingFieldPropertiesForGrid',
    'CE_ErrorLoadingNewFieldProperties',
    'CE_LoadBase64ImageAndInfoError',
    'CE_FatalFieldDataLoadError',
    'CE_DeleteFieldError',
    'CE_SaveErrorWithCode',
    'CE_FieldAdditionError',
    'CE_FailedToGetNewFieldName',
    'CE_DeleteNamedParamError',
    'CE_NewCommentSaveError',
    'CE_GenericDialogNoInitialUrlError',
    'CE_GenericDialogDataLoadError',
    'CE_PreviewFailedError',
    'CE_RevertDataError',
    'CE_CiffDataNotAvailable',
    'CE_CommonPostBodyNoCiffName',
    'CE_CommonPostBodyNoSubImage',
    'CE_PasteFieldsError',
    'CE_DataBuilderDataReadError',
    'CE_ListTypeLoadError',
    'CE_PrinterListLoadError',
    'CE_ErrorQuerySaveNeeded',
    'CE_TemplateListLoadError',
    'CE_AuthenticationError',
    'CE_LoadingSignedUserNameError',
    'CE_FieldNameSaveError',
    'CE_GridPropertySaveError',
    'CE_SignoffError',
    'CE_RevertEditedDocumentError',
    'CE_DocumentSaveError',
    'CE_LogoDeleteError',
    'CE_LogoUploadError',
    'CE_FileTooBigError',
    'CE_ImageCompressionError',
    //'CE_FileIncorrectTypeError',
    'CE_FieldSendToBackError',
    'CE_FieldSendToBackActionError',
    'CE_FieldBringToFrontError',
    'CE_FieldBringToFrontActionError',
    'CE_CirrusUserLevelDateOffsetDetailWarning',
    'CE_PackagingUploadError',
    'EditPrintheadAssignment',
    'defaultUserLevelWarningTitle',
    'CE_PackagingUploadError',

    'DeleteFieldAlertTitle',
    'DeletefieldAlertText',
    'SaveFileSuccess',
    'WarningExistingFieldName',
    'TakingControlAlertTitle',
    'TakingControlAlerttext',
    'FileIsLocked',
    'YouAreEditing',
    'CheckingFileName',
    'NoResult',
    'OneResult',
    'InvalidDate',
    'LogoUploadingTitle',
    'LogoUploadingText',
    'FileUploadSuccess',
    'UpdateLogoAlertTitle',
    'UpdateLogoAlertText',
    'AllowedFileExtensions',
    'LogoDeleteAlertTitle',
    'LogoDeleteAlertText',
    'LogoDeleteSuccess',
    'EditorSelectionAlertTitle',
    'EditorSelectionAlertText',
    'UsbRunnerWaitMessageTitle',
    'UsbRunnerWaitMessageText',
    // Tokens used by the File Chooser component
    'fileChooserLoading',
    'fileChooserLoadingFailed',
    'fileChooserBlankFileInstructions',
    'fileChooserItemBlank',

    // Tokens used by the filter bar
    'filterBarSelectCoderFilter',
    'filterBarSelectFormatFilter',
    'filterBarSearchPrompt',
    'Search',
    'filterBarLoading',
    'filterBarPrinterModel',
    'filterBarModel',
    'filterBarVariant',

    'filterBarJobSearchJobLabel',
    'filterBarJobPleaseSelectText',
    'filterBarJobNoAvailableSelectionText',
    'jobCode1Label',
    'jobCode2Label',
    'jobCode3Label',
    'showFiltersText',
    'hideFiltersText',
    'changeText',
    'hideText',
    'printerText',

    // Tokens used by the Login info component (Logged in as....)
    'loginInfoLoggedInAsText',
    'loginInfoSettingsText',
    'loginInfoLogoManagerText',
    'loginInfoPackagingManagerText',
    'loginInfoLogoutText',
    'loginInfoLoggingOutText',

    // Tokens used by the packaging manager and packaging features
    'PackagingUploadingTitle',
    'PackagingUploadingText',
    'PackagingFileUploadSuccess',
    'UpdatePackagingAlertTitle',
    'UpdatePackagingAlertText',
    'PackagingDeleteAlertTitle',
    'PackagingDeleteAlertText',
    'PackagingDeleteSuccess',


    // Icons used by the menubar and basic icon bar
    'printIconTipText',
    'downloadIconTipText',
    'backIconTipText',
    'fileIconTipText',
    'fileSaveAsIconTipText',
    'createJobBtnTipText',
    'undoIconTipText',
    'redoIconTipText',
    'editorPrintIconTipText',
    'editorDownloadIconTipText',
    'zoomInIconTipText',
    'zoomOutIconTipText',
    'zoomActualIconTipText',
    'zoomFitIconTipText',
    'alignLeftIconTipText',
    'alignCentIconTipText',
    'alignRightIconTipText',
    'alignTopIconTipText',
    'alignMiddleIconTipText',
    'alignBottomIconTipText',
    'dateCalculationIconTipText',
    'addPopupBtnTipText',
    'copyPopupBtnTipText',
    'pastePopupBtnTipText',
    'deletePopupBtnTipText',
    'showUserIconTipText',
    'JobDownloadPrompt',
    'JobDownload',
    'bringToFrontIconTipText',
    'sendToBackIconTipText',
    'templateCommentsIconTipText',
    'enablePackagingModeIconTipText',
    'panIconTipText',
    'resetIconTipText',


    // Sidebar buttons (These ID's may change as there is a bug to solve)
    'narrowSidebarNew',
    'narrowSidebarRecent',
    'narrowSidebarOpen',
    'narrowSidebarOptions',
    'narrowSidebarAccount',
    'narrowSidebarUpload',
    'narrowSidebarEdit',
    'narrowSidebarDelete',
    'narrowSidebarTemplatesTitle',
    'narrowSidebarJobsTitle',
    'narrowSidebarLogoTitle',

    'narrowSidebarContainerNewTemplateTitle',
    'narrowSidebarContainerNewTemplateText',
    'narrowSidebarContainerNewJobTitle',
    'narrowSidebarContainerNewJobText',
    'narrowSidebarContainerConfirmTemplateDeleteTitle',
    'narrowSidebarContainerConfirmTemplateDeleteText',
    'narrowSidebarContainerConfirmJobDeleteTitle',
    'narrowSidebarContainerConfirmJobDeleteText',
    'OverwriteExistingTemplateTitle',
    'OverwriteExistingTemplateText',
    'PleaseEnterDifferentName',
    'OverwriteExistingJobTitle',
    'OverwriteExistingJobText',
    'PleaseEnterDifferentJobCode',
    'Success',
    'SuccesfullJobDelete',
    'SuccesfullTemplateDelete',
    'Failure',
    'ErrorOnDelete',
    'FailedGetSelectedItemId',
    'CantDeleteTemplate',
    'FailedDeleteTemplate',
    'PleaseSelectFieldText',
    'PleaseSelectFieldLabel',

    'smartListComponentCantDeleteItems',
    'Add',

    // Tokens used by the registration box component
    // TODO once registration box is updated

    // Tokens used by the ciff editor
    'ciffEditorFilePropertiesTitleText',
    'ciffEditorFilePropertiesTabOneText',
    'ciffEditorFilePropertiesTabTwoText',
    'ciffEditorFieldPropertiesTabOneText',
    'ciffEditorFieldPropertiesTabTwoText',
    'ciffEditorFieldPropertiesTabThreeText',
    'ciffEditorFieldPropertiesTabFourText',
    'ciffEditorConfirmPageLeaveTitle',
    'ciffEditorConfirmPageLeaveText',
    'newFieldNameInput',
    'newFieldNameInputMessage',
    'newIconTipText',
    'recentIconTipText',
    'openIconTipText',
    'editIconTipText',
    'uploadIconTipText',
    'deleteIconTipText',
    'saveAsMessage',
    'EditPrintheadAssignment',

    'multiFieldPropertiesDisplayName',
    'PleaseMultiSelectFieldText',
    'BlockTextApplyButtonText',
    'AboutInfoText',
    'TextBoxPlaceholder',

    // Error tokens
    //'CE_DataMustBeBetweenLength',
    //'CE_FieldCannotBeBlank',
    //'CE_ValueMustBeBetween',

    //Multi-upload
    'CancelBtn',
    'CloseBtn',
    'UploadBtn',
    'FilesUploadTitle',
    'FilesUploadText',
    'FilesUploadFailure',
    'FilesUploadSuccess',
];


export const getErrorMessages = async (): Promise<StringIndexedObject> => {
    try {
      const response = await DesignApi.getErrorMessages();
      return response.data;
    } catch (error) {
      console.log('error', error);
      throw error;
    }
  };

  
export const getTranslations = async (): Promise<StringIndexedObject> => {
    try {
      const response = await DesignApi.getTranslations(dedupeArray(applicationTranslationTokens));
      return response.data;
    } catch (error) {
      console.log('error', error);
      throw error;
    }
  };