import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { DesignConstants } from '../utilities/DesignConstants';
import ActionTypes from '../base/ActionTypes';
import { useSelector } from '../context/Store/StoreHooks';
import { Box, Button, TextareaAutosize } from '@mui/material';
import { idify } from '../utilities/UtilityLibrary';
import { useIcons } from '../helpers/IconHook';
import { useTranslation } from '../context/Translation/TranslationHooks';

// Styled components using MUI styling

const StyledFormRow = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  padding: theme.spacing(2, 0.125),
  flexDirection: 'column',
  width: '100%',
}));

const ControlRow = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
}));

const FormControlWrapper = styled('div')<{ hasError: boolean }>(({ theme, hasError }) => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'row',
  color: 'gray',
  fontFamily: theme.typography.fontFamily,
  alignItems: 'center',
  '.form-control': {
    width: '100%',
    ...(hasError && { borderColor: theme.palette.error.main })
  }
}));

const ErrorAlert = styled('div')(({ theme }) => ({
  marginTop: '0.5em',
  color: theme.palette.error.main,
}));

const PropertyRow = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '1em',
}));

const LabelCell = styled('div')<{ fontWeight: string }>(({ fontWeight }) => ({
  fontWeight: fontWeight === "isbold" ? 'bold' : 'normal',
  marginRight: '1em',
}));

const ControlCell = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%'
}));

const StyledFormLabel = styled(Box)({
  display: 'flex',
  flexShrink: 0,
  marginRight: '10px',
  fontWeight: 'bold',
  width: '100%',
});


interface BlockTextBoxProps {
  ComponentName?: string;
  AttachedButton?: any;
  ComponentMode?: number;
  IsHidden?: boolean;
  FieldName?: string;
  ButtonIcon?: string;
  PropertyName?: string;
  Tran?: string;
  Value?: any;
  ReadOnly?: boolean;
  IsDisabled?: boolean;
  Refresh?: number;
  Redraw?: number;
  Lines?: number;
  Pattern?: string;
  AllowZeroLength?: boolean;
  Min?: number;
  Max?: number;
  Dp?: number;
  UpdatePropertyCollectionDictionary: (dictionary: any) => void;
  Type: number;
  sendActionToParent: (action: any) => void;
  Units?: string;
  refreshIndex: number;
}

export const BlockTextBoxField: React.FC<BlockTextBoxProps> = ({
  ComponentName = '',
  AttachedButton,
  ComponentMode = 0,
  IsHidden = false,
  FieldName = '',
  ButtonIcon = null,
  PropertyName = '',
  Tran = '',
  Value = '',
  ReadOnly = false,
  IsDisabled = false,
  Refresh = 0,
  Redraw = 0,
  Lines = 1,
  Pattern = '',
  AllowZeroLength = true,
  Min = 0,
  Max = 999999,
  Dp = 0,
  UpdatePropertyCollectionDictionary,
  Type,
  sendActionToParent,
  Units,
  refreshIndex,
}) => {

  const icons = useIcons();

  const { getTranslatedErrorMessage } = useTranslation();

  const isDialogBlocked = useSelector((state) => state.dialogState.isDialogBlocked);
  const doesDialogHaveError = useSelector((state) => state.dialogState.doesDialogHaveError);
  const isPropertyGridBlocked = useSelector((state) => state.propertyGridState.isPropertyGridBlocked);

  // State variables
  const [initialised, setInitilised] = useState(false);

  const [propertyValue, setPropertyValue] = useState(Value !== null ? Value : '');
  const [validationPattern, setValidationPattern] = useState(Pattern);
  const [isNumericTextBox, setIsNumericTextBox] = useState(false);
  const [buttonLabel, setButtonLabel] = useState(AttachedButton?.Tran || 'Apply');
  const [labelFontWeight, setLabelFontWeight] = useState('isnormal');
  const [controlInError, setControlInError] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showButton, setShowButton] = useState(false);
  const [patternHint] = useState('');
  const fieldRequired = !AllowZeroLength;

  const firstPropertyValue = useMemo(() => Value !== null ? Value : '', [Value]);
  const buttonIcon = useMemo(() => AttachedButton && AttachedButton.ButtonIcon ? AttachedButton.ButtonIcon : null, [AttachedButton]);

  // Derived state
  const isDisabled = useMemo(() => IsDisabled || ReadOnly || isDialogBlocked || isPropertyGridBlocked, [IsDisabled, ReadOnly, isDialogBlocked, isPropertyGridBlocked]);
  const componentMode = useMemo(() => ComponentMode || 0, [ComponentMode]);

  const getControlValueObject = useCallback(() => {
    return {
      PropertyName,
      PropertyValue: propertyValue,
      RefreshType: Refresh,
      RedrawType: Redraw,
    };
  }, [PropertyName, propertyValue, Refresh, Redraw]);

  const updatePropertyDictionary = useCallback(() => {
    if (UpdatePropertyCollectionDictionary) {
      const collectionValues = {
        [PropertyName]: getControlValueObject(),
      };
      UpdatePropertyCollectionDictionary(collectionValues);
    }
  }, [PropertyName, UpdatePropertyCollectionDictionary, getControlValueObject]);


  const handleFocus = (event) => {
    event.preventDefault();
    setLabelFontWeight('isbold');
  };

  const handleBlur = (event) => {
    event.preventDefault();
    setLabelFontWeight('isnormal');
  };

  const handleButtonClick = useCallback(() => {
    if (AttachedButton) {
      sendActionToParent({
        type: ActionTypes.UP_ExecuteButtonLinkAction,
        payload: {
          Link: AttachedButton.Action,
          Payload: AttachedButton.Payload,
          FieldName,
          PropertyName,
          PropertyValue: propertyValue,
        },
      });
    }
  }, [AttachedButton, FieldName, PropertyName, propertyValue, sendActionToParent]);


  const isControlValueValid = useCallback((value) => {
    let validationResult = {
      Valid: false,
      ValidationCode: '',
      ErrorMessage: '',
    };

    if (isDisabled) {
      return {
        Valid: true,
        ValidationCode: '',
        ErrorMessage: '',
      };
    }

    if ((!value || value === '') && !fieldRequired) {
      return {
        Valid: true,
        ValidationCode: '',
        ErrorMessage: '',
      };
    }

    if (isNumericTextBox) {
      const numVal = Number(value);
      if (!isNaN(numVal)) {
        validationResult.Valid = numVal >= Min && numVal <= Max;
        if (!validationResult.Valid) {
          if (numVal < Min) {
            validationResult.ValidationCode = 'CE_ValueMustBeGreaterThan';
            validationResult.ErrorMessage = `${getTranslatedErrorMessage('CE_ValueMustBeGreaterThan')} ${Min}!`;
          } else if (numVal > Max) {
            validationResult.ValidationCode = 'CE_ValueMustBeSmallerThan';
            validationResult.ErrorMessage = `${getTranslatedErrorMessage('CE_ValueMustBeSmallerThan')} ${Max}!`;
          }
        }
        return validationResult;
      }
    }

    if (!validationPattern && !fieldRequired) {
      return {
        Valid: true,
        ValidationCode: '',
        ErrorMessage: '',
      };
    }

    if (!isNumericTextBox) {
      const valueLength = value ? value.length : 0;
      if (valueLength < Min || valueLength > Max) {
        validationResult.Valid = false;
        validationResult.ValidationCode = 'CE_DataMustBeBetweenLength';
        const errMsg = getTranslatedErrorMessage('CE_DataMustBeBetweenLength');
        validationResult.ErrorMessage = errMsg.replace('%1', Min.toString()).replace('%2', Max.toString());
        return validationResult;
      }
    }

    const pattern = new RegExp(validationPattern);
    if (pattern.test(value)) {
      validationResult = {
        Valid: true,
        ValidationCode: '',
        ErrorMessage: '',
      };
    } else {
      const errMsg = getTranslatedErrorMessage('CE_ValidValueRequired');
      validationResult = {
        Valid: false,
        ValidationCode: 'CE_ValidValueRequired',
        ErrorMessage: errMsg.replace('%1', patternHint.toString()),
      };
    }

    return validationResult;
  }, [isDisabled, fieldRequired, isNumericTextBox, validationPattern, Min, Max, patternHint]);

  const propertyValueChanged = useCallback((newValue, updateToServerIfValid = false) => {
    if (isDisabled || !PropertyName) {
      return;
    }

    let validatedValue = newValue;
    if (isNumericTextBox && new RegExp(validationPattern).test(newValue)) {
      validatedValue = Number(newValue).toFixed(Dp);
    }

    const validationResult = isControlValueValid(validatedValue);
    if (!validationResult.Valid) {
      sendActionToParent({
        type: ActionTypes.UP_PageControlValidationStateAction,
        payload: {
          PropertyName,
          State: DesignConstants.controlStateInvalid,
        },
      });

      setErrorMessage(validationResult.ErrorMessage);
      setControlInError(true);

      if (validationResult.ErrorMessage) {
        setShowErrorAlert(true);
      }
      return;
    } else if (controlInError) {
      sendActionToParent({
        type: ActionTypes.UP_PageControlValidationStateAction,
        payload: {
          PropertyName,
          State: DesignConstants.controlStateValid,
        },
      });

      setControlInError(false);
      setShowErrorAlert(false);
      setErrorMessage('');
    }

    if (componentMode === 0) {
      updatePropertyDictionary();
      if (isDisabled || isDialogBlocked || doesDialogHaveError) {
        return;
      }
    } else {
      if (isDisabled || isPropertyGridBlocked) {
        return;
      }
      updatePropertyDictionary();
    }

    if (updateToServerIfValid) {
      if (Refresh === 1 || Redraw === 1 || componentMode === 1) {
        sendActionToParent({
          type: ActionTypes.UP_RefreshPaneAction,
          payload: {
            FieldName,
            PropertyName,
            PropertyValue: propertyValue,
            RefreshType: Refresh,
            RedrawType: Redraw,
          },
        });
      }
    }
  }, [isDisabled, PropertyName, isNumericTextBox, validationPattern, isControlValueValid, controlInError, componentMode, Dp, updatePropertyDictionary, isDialogBlocked, doesDialogHaveError, isPropertyGridBlocked, Refresh, Redraw, sendActionToParent, FieldName, propertyValue]);

  const onTextAreaLostFocus = useCallback(() => {
    propertyValueChanged(propertyValue, true);
  }, [propertyValue, propertyValueChanged]);


  const validateControl = useCallback(() => {
    const validationResult = isControlValueValid(propertyValue);
    if (!validationResult.Valid) {
      sendActionToParent({
        type: ActionTypes.UP_PageControlValidationStateAction,
        payload: {
          PropertyName,
          State: DesignConstants.controlStateInvalid,
        },
      });

      setErrorMessage(validationResult.ErrorMessage);
      setControlInError(true);

      if (validationResult.ErrorMessage) {
        setShowErrorAlert(true);
      }
      return false;
    } else if (controlInError) {
      sendActionToParent({
        type: ActionTypes.UP_PageControlValidationStateAction,
        payload: {
          PropertyName,
          State: DesignConstants.controlStateValid,
        },
      });

      setControlInError(false);
      setShowErrorAlert(false);
      setErrorMessage('');
    }
    return true;
  }, [PropertyName, controlInError, isControlValueValid, propertyValue, sendActionToParent]);

  const keyChecks = useCallback((event) => {
    if (event.keyCode !== undefined) {
      if (event.ctrlKey && (event.keyCode === 10 || event.keyCode === 13 || event.key === 'Enter')) {
        event.target.blur();
        return false;
      }
    }
    return true;
  }, []);

  const valueForDisplay = useMemo(() => isNumericTextBox && new RegExp(validationPattern).test(propertyValue)
    ? Number(propertyValue).toFixed(Dp)
    : propertyValue, [isNumericTextBox, validationPattern, propertyValue, Dp]);

  const handleChange = useCallback((e) => {
    const newValue = e.target.value;
    setPropertyValue(newValue);
    propertyValueChanged(newValue);
  }, [propertyValueChanged]);

  useEffect(() => {
    setInitilised(false);
    setPropertyValue(firstPropertyValue);
  }, [refreshIndex, firstPropertyValue]);

  useEffect(() => {

    if (!initialised) {
      setInitilised(true);
      if (Type === DesignConstants.uiTypeNumericTextBox) {
        setIsNumericTextBox(true);
        setValidationPattern('^-?[0-9]+\\.?[0-9]?$');
      } else if (Pattern) { // An empty string in JS equates to false
        setValidationPattern(Pattern);
      }

      if (AttachedButton && AttachedButton.Tran) {
        setButtonLabel(AttachedButton.Tran);
      }

      setTimeout(() => {
        if (ComponentName) {
          validateControl();
        }

        updatePropertyDictionary();
      }, 10);

      setShowButton(!!AttachedButton || !ButtonIcon);
      if (AttachedButton?.Tran) {
        setButtonLabel(AttachedButton?.Tran);
      }
    }
  }, [Value, AttachedButton, Pattern, Min, Max, Dp, Type, ButtonIcon, ComponentName, updatePropertyDictionary, validateControl, initialised]);

  const IconElement = buttonIcon ? icons[buttonIcon] : null;

  return (
    <>
      {componentMode === 0 && !IsHidden && (
        <>
          <StyledFormRow>
            <StyledFormLabel>
              {Units && Units !== 'NU' ? `${Tran} (${Units})` : Tran}
            </StyledFormLabel>
            <FormControlWrapper hasError={controlInError}>
              <TextareaAutosize
                id={`control_${PropertyName.toLowerCase().replace(/\s+/g, "_")}`}
                className="form-control vjcontrol"
                disabled={isDisabled}
                required={fieldRequired}
                maxLength={Max}
                minLength={Min}
                value={valueForDisplay}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onKeyDown={keyChecks}
                maxRows={Lines || 2}
                minRows={2}
                style={{ width: '100%', height: 'auto', resize: 'none', color: '#595959', border: '1px solid gray', borderRadius: '5px', padding: '5px' }}
                onBlurCapture={onTextAreaLostFocus}
              // inputProps={{ pattern: validationPattern || undefined }}
              />
              {showButton && (
                <Button
                  id={`controlbtn_${idify(PropertyName)}`}
                  size="small"
                  sx={{ marginLeft: '10px' }}
                  onClick={handleButtonClick}
                  disabled={isDisabled || !valueForDisplay}
                  color="primary"
                  variant="contained">
                  {IconElement ? <IconElement /> : buttonLabel}
                </Button>
              )}
            </FormControlWrapper>
          </StyledFormRow>
          {showErrorAlert && (
            <ErrorAlert>
              <span>{errorMessage}</span>
            </ErrorAlert>
          )}
        </>
      )}

      {componentMode === 1 && !IsHidden && (
        <PropertyRow>
          <ControlRow>
            <LabelCell fontWeight={labelFontWeight}>
              <span>{Units && Units !== 'NU' ? `${Tran} (${Units})` : Tran}</span>
            </LabelCell>
            <ControlCell>
              <TextareaAutosize
                id={`control_${FieldName.toLowerCase().replace(/\s+/g, "_")}_${PropertyName.toLowerCase().replace(/\s+/g, "_")}`}
                className="propertyControl vjcontrol"
                disabled={isDisabled}
                required={fieldRequired}
                maxLength={Max}
                minLength={Min}
                placeholder="No Value"
                value={valueForDisplay}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onKeyDown={keyChecks}
                maxRows={Lines || 2}
                minRows={2}
                style={{ width: '100%', height: 'auto', resize: 'none' }}
                onBlurCapture={onTextAreaLostFocus}
              // inputProps={{ pattern: validationPattern || undefined }}
              />
              {showButton && (
                <Button
                  id={`controlbtn_${idify(PropertyName)}`}
                  size="small"
                  sx={{ marginLeft: '10px' }}
                  onClick={handleButtonClick}
                  disabled={ReadOnly || !valueForDisplay}
                  color="primary"
                  variant="contained">
                  {IconElement ? <IconElement /> : buttonLabel}
                </Button>
              )}
            </ControlCell>
          </ControlRow>
          {showErrorAlert && (
            <div className="messageRow">
              <span>{errorMessage}</span>
            </div>
          )}
        </PropertyRow>
      )}
    </>
  );
};