import { useQuery } from 'react-query';
import { getErrorMessages, getTranslations } from './DesignAppActions';

const cachingOptions = {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  };

  const QK_DESIGNAPP_GET_ERROR_MESSAGES = 'QK_DESIGNAPP_GET_ERROR_MESSAGES';
  const QK_DESIGNAPP_GET_TRANSLATIONS = 'QK_DESIGNAPP_GET_TRANSLATIONS';

  export const useGetErrorMessages = () => useQuery([QK_DESIGNAPP_GET_ERROR_MESSAGES], () => getErrorMessages(), cachingOptions);

  export const useGetTranslations = () => useQuery([QK_DESIGNAPP_GET_TRANSLATIONS], () => getTranslations(), cachingOptions);