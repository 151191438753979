import { useState, useEffect } from 'react';
import { ProgressIndicator } from '../../components/ProgressIndicator/ProgressIndicator';
import ActionTypes from './base/ActionTypes';
import { MemoryStoreLibrary } from './utilities/MemoryStoreLibrary';
import './DesignApp.css'; // Import the styles

import { useDispatch } from './context/Store/StoreHooks';
import { CiffEditorMainPanel } from './CiffEditorMainPanel';
import { styled } from '@mui/styles';

const LoaderBox = styled('div')(() => ({
  height: '100vh',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'left top',
  backgroundSize: '100% 100%',
  display: 'flex',
  color: 'white',
}));

export const DesignApp = () => {
  const [contentDataLoadCompleted, setContentDataLoadCompleted] = useState(false);
  const [fileName, setFileName] = useState<string>('');

  const dispatch = useDispatch();

  useEffect(() => {
    const timer = setTimeout(() => {
      const ciffName = MemoryStoreLibrary.getString('ciffName');
      if (ciffName) {
        console.log('ciffName', ciffName);
        setFileName(ciffName);
      }
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (fileName) {
      const ciffDescriptorRequestObj = {
        pageName: 'edit',
        editFile: fileName,
      };
      dispatch({ type: ActionTypes.STORE_UpdateCurrentCiffParamsAction, payload: ciffDescriptorRequestObj });
      MemoryStoreLibrary.setString('ciffDescriptorRequestObj', JSON.stringify(ciffDescriptorRequestObj));
    }
  }, [fileName]);

  return (
    <>
      {!contentDataLoadCompleted && (
        <>
          <ProgressIndicator />
          <LoaderBox />
        </>
      )}
      {fileName && <CiffEditorMainPanel setContentDataLoadCompleted={setContentDataLoadCompleted} />}
    </>
  );
};
