
//Libraries
import { DesignConstants } from './DesignConstants';

//Dependencies
import ActionTypes from '../base/ActionTypes';


export function updateVisiblePropertyGridPanelIdInvoker(dispatch, propertyGridPanelId: string) {
   dispatch({ type: ActionTypes.STORE_UpdateVisiblePropertyGridPanelIdAction, payload: propertyGridPanelId });
}

export function setEditorTemplateTimeStampInvoker(dispatch, timestamp: string) {
   dispatch({ type: ActionTypes.STORE_UpdateEditorTemplateTimeStampAction, payload: timestamp });
}


export function setStoreRedoAndUndoStackInvoker(dispatch, stacks: { undo?: Array<any>; redo?: Array<any> }) {
   dispatch({ type: ActionTypes.STORE_UpdateRedoAndUndoStack, payload: stacks });
}

export function setUserLevelWarningDialogVisibilityInvoker(dispatch, isVisible: boolean) {
   dispatch({ type: ActionTypes.STORE_UpdateUserLevelWarningDialogIsVisibleAction, payload: isVisible });
}

export function setInputDialogVisibilityInvoker(dispatch, isVisible: boolean) {
   dispatch({ type: ActionTypes.STORE_UpdateInputDialogIsVisibleAction, payload: isVisible });
}

export function setInputBoxTextBoxInvoker(dispatch, textBox: string) {
   dispatch({ type: ActionTypes.STORE_UpdateInputDialogInputTextAction, payload: textBox });
}

export function setInputBoxHelpMessageInvoker(dispatch, message: string) {
   dispatch({ type: ActionTypes.STORE_UpdateInputDialogHelpMessageAction, payload: message });
}

export function setInputBoxHelpMessageColorInvoker(dispatch, messageColor: string) {
   dispatch({ type: ActionTypes.STORE_UpdateInputDialogHelpMessageColorAction, payload: messageColor });
}

export function setDecisionBoxVisibilityInvoker(dispatch, isVisible: boolean) {
   dispatch({ type: ActionTypes.STORE_UpdateDecisionDialogIsVisibleAction, payload: isVisible });
}

export function setPreviouslySelectedTabIndexInvoker(dispatch, id: number) {
   dispatch({ type: ActionTypes.STORE_SetPreviousSelectedDialogTabIndexAction, payload: id });
}

export function setSelectedTabIndexInvoker(dispatch, id: number) {
   dispatch({ type: ActionTypes.STORE_SetSelectedDialogTabIndexAction, payload: id });
}

export function setOverlayWithDelayInvoker(dispatch, visible: boolean) {
   if (visible) {
      setTimeout(() => {
         dispatch({ type: ActionTypes.STORE_UpdateScreenOverlayVisible, payload: visible });
      }, DesignConstants.overlayDelay);
   }
   else {
      dispatch({ type: ActionTypes.STORE_UpdateScreenOverlayVisible, payload: visible });
   }
}

export function setOverlayWithoutFadingOnInvoker(dispatch, visible: boolean) {
   dispatch({ type: ActionTypes.STORE_UpdateScreenOverlayVisibilityAndFadingAction, payload: { visible, fadingOn: false } });
}

export function setOverlayFadingOnInvoker(dispatch, fadingOn: boolean) {
   dispatch({ type: ActionTypes.STORE_UpdateScreenOverlayFadingOnAction, payload: { fadingOn } });
}

export function setOverlayVisibilityInvoker(dispatch, visible: boolean) {
   dispatch({ type: ActionTypes.STORE_UpdateScreenOverlayVisibilityAndFadingAction, payload: visible });
}

export function setSelectedTabIdInvoker(dispatch, id: string) {
   dispatch({ type: ActionTypes.STORE_SetSelectedGridTabIdAction, payload: id });
}


export function updatInitialAddFieldPositionInvoker(dispatch, initalPositionObj) {
   dispatch({ type: ActionTypes.STORE_UpdatInitialAddFieldPositionAction, payload: initalPositionObj });
}

export function updateCurrentCiffFileDescriptorInvoker(dispatch, fileDescriptor) {
   dispatch({ type: ActionTypes.STORE_UpdateCurrentCiffParamsAction, payload: fileDescriptor });
}


