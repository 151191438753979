import { useMemo, SetStateAction, Dispatch } from "react";

import {
  useAppGlobalState,
  useVrsTranslationState,
} from "../../../context/AppContext/AppContext";
import { FormControl, FormControlLabel } from "@mui/material";
import { TextInput } from "../../../components/TextInput/TextInput";
import { IUserProfile } from "../../../interfaces/User/IUserProfile";
import { NewPassword } from "../NewPassword/NewPassword";
import { SimpleDropdown } from "../../../components/Basic/SimpleDropdown/SimpleDropdown";
import { StyledSwitch } from "../../../components/StyledSwitch/StyledSwitch";
import UserHelper from "../../../helpers/UserHelper";
import {
  BASE_DISPLAY_UNIT,
  IMPERIAL_DISPLAY_UNIT,
  METRIC_DISPLAY_UNIT,
} from "../../../constants/global";
import { styled } from "@mui/material/styles";
import { useLocalStorage } from "../../../context/LocalStorageContext/LocalStorageContext";

const FormControlBox = styled(FormControl)(({ theme }) => ({
  display: "flex",
  flexFlow: "row",
  maxWidth: theme.spacing(80),
  alignItems: "center",
  "&:nth-last-of-type(n+2)": {
    marginBottom: theme.spacing(1.875),
  }
}));

const ProfileDetailContainer = styled("div")({
  paddingTop: "10px",
});

const canAccessVrsWithField = (field) =>
  !!UserHelper.CanWithField("vrs", "authModule", field);

interface EditProfileDetailsProps {
  currentProfile: IUserProfile;
  setCurrentProfile: Dispatch<SetStateAction<IUserProfile | null>>;
  errors: any;
  saving: boolean;
}

const EditProfileDetails = ({
                              currentProfile,
                              setCurrentProfile,
                              errors,
                              saving,
                            }: EditProfileDetailsProps) => {
  const { _T } = useVrsTranslationState();
  const userLocalStorage = useLocalStorage();
  const { isIdentityUser } = useAppGlobalState();

  // Check if the user is a design only user
  const isDesignOnlyUser = currentProfile?.DesignOnly === true;

  const updateData = (data) => {
    const { name, value } = data;

    if(value === 0){
      const defaultBrowserLanguage = navigator.language.split(/[-_]/)[0] || 'en';
      userLocalStorage.setItem("vrsUserLocale", defaultBrowserLanguage );
    }
    setCurrentProfile((state: any) => {
      return { ...state, [name]: value };
    });
  };

  const languageOptions = useMemo(() => {
    if (currentProfile && currentProfile.Languages) {
      return currentProfile.Languages.map((el) => ({
        value: el.LanguageId,
        text: _T(el.LocalName),
      }));
    }
    return [];
  }, [currentProfile, _T]);

  const displayUnitOptions = useMemo(
    () => [
      { value: BASE_DISPLAY_UNIT, text: _T("Base") },
      { value: METRIC_DISPLAY_UNIT, text: _T("Metric") },
      { value: IMPERIAL_DISPLAY_UNIT, text: _T("Imperial") },
    ],
    [_T]
  );

  const isExternalUser =
    canAccessVrsWithField("Administrator") ||
    canAccessVrsWithField("Maintenance") ||
    canAccessVrsWithField("User");

  const updateDataWithSelect = (name: string) => (value) => {
    updateData({ name, value });
  };

  const updateDataWithText = (name: string) => (event) => {
    updateData({ name, value: event.target.value });
  };

  // Preference settings - shared by both user types
  const renderPreferenceSettings = () => (
    <>
      <FormControlBox variant="outlined">
        <SimpleDropdown
          id={"DisplayUnit"}
          name="DisplayUnit"
          canRemoveAll={false}
          label={_T("Display Unit")}
          onChange={updateDataWithSelect("DisplayUnit")}
          placeholder={""}
          options={displayUnitOptions}
          value={(currentProfile && currentProfile.DisplayUnit) || ""}
          variant="outlined"
          disabled={saving}
          error={errors["DisplayUnit"]}
        />
      </FormControlBox>
      <FormControlBox>
        <FormControlLabel
          control={
            <StyledSwitch
              name="use24HourTime"
              checked={currentProfile && currentProfile.Prefer24HourTimeFormat}
              onChange={(_, value) =>
                updateData({
                  name: "Prefer24HourTimeFormat",
                  value,
                })
              }
            />
          }
          label={_T("Prefer 24 hour time")}
        />
      </FormControlBox>
    </>
  );

  // Password section - shared by both user types
  const renderPasswordSection = () => {
    if (isIdentityUser) return null;

    return (
      <>
        <TextInput
          fieldName="CurrentPassword"
          label={_T("Current Password")}
          value={(currentProfile && currentProfile.CurrentPassword) || ""}
          onChange={updateDataWithText("CurrentPassword")}
          placeholder=""
          type="password"
          errors={errors}
          isDisabled={saving}
          errorInPlantText={true}
          ignoreErrorText={true}
        />
        <NewPassword
          loading={saving}
          errors={errors}
          newPassword={currentProfile?.NewPassword}
          confirmPassword={currentProfile?.ConfirmPassword}
          updateDataWithText={updateDataWithText}
        />
      </>
    );
  };

  // Full profile fields - only for regular users
  const renderFullProfileFields = () => {
    if (isDesignOnlyUser) return null;

    return (
      <>
        <TextInput
          fieldName="FirstName"
          label={_T("First Name")}
          placeholder=""
          value={(currentProfile && currentProfile.FirstName) || ""}
          onChange={updateDataWithText("FirstName")}
          isDisabled={saving}
          isRequired={true}
          errors={errors}
          errorInPlantText={true}
          ignoreErrorText={true}
        />
        <TextInput
          fieldName="LastName"
          label={_T("Last Name")}
          value={(currentProfile && currentProfile.LastName) || ""}
          onChange={updateDataWithText("LastName")}
          placeholder=""
          isRequired={true}
          isDisabled={saving}
          errors={errors}
          errorInPlantText={true}
          ignoreErrorText={true}
        />
        <TextInput
          fieldName="NotificationEmail"
          label={_T("Notification Email")}
          value={(currentProfile && currentProfile.NotificationEmail) || ""}
          onChange={updateDataWithText("NotificationEmail")}
          placeholder=""
          isRequired={true}
          isDisabled={saving}
          errors={errors}
          errorInPlantText={true}
          ignoreErrorText={true}
        />
        {!isExternalUser && (
          <TextInput
            fieldName="Country"
            label={_T("Country")}
            value={(currentProfile && currentProfile.Country) || ""}
            onChange={updateDataWithText("Country")}
            placeholder=""
            isDisabled={true}
            isRequired={true}
            errors={errors}
            errorInPlantText={true}
            ignoreErrorText={true}
          />
        )}
        <TextInput
          fieldName="AccessLevel"
          label={_T("User Role")}
          value={(currentProfile && currentProfile.AccessLevel) || ""}
          onChange={updateDataWithText("AccessLevel")}
          placeholder=""
          isDisabled={true}
          isRequired={true}
          errors={errors}
          errorInPlantText={true}
          ignoreErrorText={true}
        />
        <FormControlBox>
          <SimpleDropdown
            id={"LanguageId"}
            name="LanguageId"
            canRemoveAll={false}
            label={_T("Language")}
            onChange={updateDataWithSelect("LanguageId")}
            placeholder={""}
            options={languageOptions}
            value={languageOptions.find((el) => el.value === currentProfile?.LanguageId) ? currentProfile?.LanguageId : ""}
            variant="outlined"
            disabled={saving}
            error={errors["LanguageId"]}
          />
        </FormControlBox>
      </>
    );
  };

  return (
    <ProfileDetailContainer>
      {renderFullProfileFields()}
      {renderPreferenceSettings()}
      {renderPasswordSection()}
    </ProfileDetailContainer>
  );
};

export default EditProfileDetails;
