import { useCallback, useState } from "react";

import {
  useVrsTranslationState,
} from "../../../context/AppContext/AppContext";
import { DialogWithBackdropClick } from "../../../components/DialogWithBackdropClick/DialogWithBackdropClick";
import { Box, Button, Tab, Tabs } from "@mui/material";
import { StyledDialogTitle } from "../../../components/StyledDialogTitle/StyledDialogTitle";
import { DialogActions, DialogContent, DialogProps } from "@mui/material";

import WorkScheduleBar from "../workschedule/WorkScheduleBar/WorkScheduleBar";
import WorkScheduleTable from "../workschedule/WorkScheduleTable/WorkScheduleTable";
import EditProfileDetails from "../EditProfileDetails/EditProfileDetails";
import { useProfile } from "../../../hooks/ProfileHook/ProfileHook";
import { ConfirmSaveWorkScheduleDialog } from "../ConfirmSaveWorkScheduleDialog/ConfirmSaveWorkScheduleDialog";
import { useConfigActions } from "../../../actions/configActions";
import { myGrayShade2Alpha10 } from "../../../constants/colors";
import { styled } from "@mui/material/styles";


const StyledDialogActions = styled(DialogActions)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
});

const DetailContainer = styled("div")(({ theme }) => ({
  width: "100%",
  minWidth: "580px !important",
  maxWidth: "580px !important",
  [theme.breakpoints.only("xs")]: {
    minWidth: "300px !important",
    maxWidth: "300px !important",
  }
}));

const TabContainer = styled("div")({
  borderBottom: `thin solid ${myGrayShade2Alpha10}`,
});

const DetailTopContainer = styled("div")({
  overflowX: "auto",
  display: "flex",
  alignItems: "center",
  width: "100%",
});

const InnerDetailContainer = styled("div")({
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "center",
  minHeight: "400px",
  width: '100%'
});

const StyledDialogContent = styled(DialogContent)({
  padding: "0px !important",
  margin: 0,
  width: '100%',
  display: 'flex',
  justifyContent: 'center'

});

function a11yProps(index: number) {
  return {
    id: `profile-tab-${index}`,
    "aria-controls": `profile-tabpanel-${index}`,
  };
}

interface EditProfileDialogProps {
  open: boolean;
  onClose: () => void;
  maxWidth?: DialogProps["maxWidth"];
}

const EditProfileDialog = ({
  open,
  onClose,
  maxWidth,
}: EditProfileDialogProps) => {
  const { _T } = useVrsTranslationState();
  const configActions = useConfigActions();
  const performOnClose = useCallback(() => {
    configActions.setProfileChanged();
    onClose();
  }, [onClose, configActions]);

  const {
    currentProfile,
    setCurrentProfile,
    saveProfile,
    resetIndex,
    refreshIndex,
    setResetIndex,
    timeError,
    onTimeError,
    dataReady,
    dirty,
    scheduleDirty,
    errors,
    saving,
  } = useProfile({ onClose: performOnClose, includeAlerts: false });

  // Check if the user is a design only user
  const isDesignOnlyUser = currentProfile?.DesignOnly === true;

  const [activeTabIndex, setActiveTabIndex] = useState(0);

  // Reset tab to 0 if we're on work schedule tab and user is changed to design only
  // This prevents showing an empty tab if the user was on work schedule tab when their role was changed
  useCallback(() => {
    if (isDesignOnlyUser && activeTabIndex === 1) {
      setActiveTabIndex(0);
    }
  }, [isDesignOnlyUser, activeTabIndex]);

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setActiveTabIndex(newValue);
  };

  const [isSaveProfileConfirmVisible, setIsSaveProfileConfirmVisible] =
    useState(false);

  const onCloseRegisterConfirmDialog = useCallback(
    (result) => {
      if (result) {
        saveProfile();
      }
      setIsSaveProfileConfirmVisible(false);
    },
    [saveProfile]
  );

  const checkSaveProfileSite = useCallback((_) => {
    setIsSaveProfileConfirmVisible(true);
  }, []);

  return (
    <>
      <DialogWithBackdropClick
        disableBackdropClick
        fullWidth={true}
        open={open}
        maxWidth={maxWidth || "sm"}
      >
        {dataReady && currentProfile ? (
          <>
            <StyledDialogTitle id="edit-profile-dialog-title">
              <div>{_T("Edit Profile")}</div>
            </StyledDialogTitle>
            <StyledDialogContent dividers>

              <DetailContainer>
                <DetailTopContainer>
                  <InnerDetailContainer>
                    <Box sx={{ width: '100%', padding: '5px' }}>
                      {/* Only show tabs when not a design only user */}
                      {!isDesignOnlyUser ? (
                      <TabContainer>
                        <Tabs
                          value={activeTabIndex}
                          onChange={handleChange}
                          indicatorColor="secondary"
                          textColor="primary"
                        >
                          <Tab
                            label={_T("Details")}
                            {...a11yProps(0)}
                            key={0}
                          />
                          <Tab
                            label={_T("Work Schedule")}
                            {...a11yProps(1)}
                            key={1}
                          />
                        </Tabs>
                      </TabContainer>
                      ) : null}

                      {/* Always show details panel for design only users or when on tab 0 */}
                      {(isDesignOnlyUser || activeTabIndex === 0) && (
                        <EditProfileDetails
                          currentProfile={currentProfile}
                          setCurrentProfile={setCurrentProfile}
                          errors={errors}
                          saving={saving}
                        />
                      )}

                      {/* Only show work schedule panel when not a design only user and on tab 1 */}
                      {!isDesignOnlyUser && activeTabIndex === 1 && (
                        <>
                        <WorkScheduleBar
                          currentProfile={currentProfile}
                          setCurrentProfile={setCurrentProfile}
                          setResetIndex={setResetIndex}
                        />
                        <WorkScheduleTable
                          currentProfile={currentProfile}
                          setCurrentProfile={setCurrentProfile}
                          resetIndex={resetIndex}
                          onTimeError={onTimeError}
                          refreshIndex={refreshIndex}
                          errors={errors}
                          setResetIndex={setResetIndex}
                        />
                        </>
                      )}
                    </Box>
                  </InnerDetailContainer>
                </DetailTopContainer>
              </DetailContainer>
            </StyledDialogContent>
            <StyledDialogActions>
              <Button
                id="save-btn"
                variant="contained"
                color="primary"
                disabled={saving || !dirty || (scheduleDirty && timeError)}
                onClick={scheduleDirty ? checkSaveProfileSite : saveProfile}
              >
                {_T("Save")}
              </Button>
              <Button
                id="cancel-btn"
                variant="contained"
                onClick={onClose}>
                {_T("Cancel")}
              </Button>
            </StyledDialogActions>
          </>
        ) : null}
      </DialogWithBackdropClick>
      {!isDesignOnlyUser && (
      <ConfirmSaveWorkScheduleDialog
        saving={saving}
        open={isSaveProfileConfirmVisible}
        workSchedule={currentProfile?.WorkSchedules}
        onClose={onCloseRegisterConfirmDialog}
      />
      )}
    </>
  );
};

export default EditProfileDialog;
