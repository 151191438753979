import ActionTypes from '../base/ActionTypes';
import { IEditorState } from '../context/Store/StoreTypes';
import { deepSimplify } from '../EditorUtilityService';
import { IAction } from '../interfaces/IAction';

export function EditorReducer(
  state: IEditorState = {
    currentCiffParams: null,
    leftHandSideButtonsBlocked: false,
    undoStack: [],
    redoStack: [],
    editorTemplateTimeStamp: 'Not Initialised',
  },
  action: IAction
) {
  switch (action.type) {
    case ActionTypes.STORE_UpdateEditorTemplateTimeStampAction:
      return {
        ...state,
        editorTemplateTimeStamp: action.payload,
      };
    case ActionTypes.STORE_UpdateCurrentCiffParamsAction:
      return {
        ...state,
        currentCiffParams: action.payload,
      };

    case ActionTypes.STORE_UpdateRedoAndUndoStack: {
      const undoStack = deepSimplify(action.payload.undo ? [...action.payload.undo] : state.undoStack);
      const redoStack = deepSimplify(action.payload.redo ? [...action.payload.redo] : state.redoStack);

      return {
        ...state,
        undoStack,
        redoStack,
      };
    }
    default:
      return state;
  }
}
