import React from "react";
import { CiffEditorContext }                     from "./CiffEditorHooks";
import {CiffEditorProviderProps, defaultContext} from "./CiffEditorTypes";

export const CiffEditorProvider: React.FC<CiffEditorProviderProps> = ({children, value}) => {
  const contextValue = {
    ...defaultContext,
    ...value,
  };

  return (
    <CiffEditorContext.Provider
      value={contextValue}>
      {children}
    </CiffEditorContext.Provider>
  );
};
