import {ISubImageHeader} from "../../ISubImageHeader";
import {ReactNode}       from "react";


export interface CiffEditorContextType {
  // Editor state
  mxGraphInstance: any;
  isBlocked: React.RefObject<boolean>;
  ciffDescriptor: any;
  subImageHeader: ISubImageHeader;
  actualSizeZoomFactor: number;

  // UI state controls
  setShowBackdrop: (show: boolean) => void;
  showBackdrop: boolean;

  // Editor control functions
  blockCiffEditor: (blocked: boolean) => void;
  customZoomToActual: (header: any, factor: number) => Promise<void>;

  // Utility functions
  showMessageBox: (title: string, message: string, isSuccess: boolean) => void;
  toaster: { success: (message: string) => void };
}

export const defaultContext: CiffEditorContextType = {
  mxGraphInstance: null,
  isBlocked: { current: false },
  ciffDescriptor: null,
  subImageHeader: {} as ISubImageHeader,
  actualSizeZoomFactor: 1,
  setShowBackdrop: () => {},
  showBackdrop: false,
  blockCiffEditor: () => {},
  customZoomToActual: async () => {},
  showMessageBox: () => {},
  toaster: { success: () => {} }
};

export interface CiffEditorProviderProps {
  children: ReactNode;
  value: Partial<CiffEditorContextType>;
}



