import { createContext, Dispatch, Dispatch as ReactDispatch } from 'react';
import { ICompanyState } from '../../interfaces/Reducer/ICompanyState';

export interface IEditorState {
  leftHandSideButtonsBlocked: boolean;
  undoStack: any[];
  redoStack: any[];
  editorTemplateTimeStamp: string;
  currentCiffParams: any;
}

export interface IPropertyGridState {
  selectedTabId: string;
  isPropertyGridBlocked: boolean;
  visiblePropertyGridPanelId: string;
}

export interface IDialogState {
  previousSelectedTabIndex: number;
  selectedTabIndex: number;
  alertBoxTitleText: string;
  alertBoxBodyText: string;
  alertBoxBodyIcon: string;
  alertBoxIconColor: string;

  inputBoxTitleText: string;
  inputBoxBodyText: string;
  inputBoxBodyIcon: string;
  inputBoxIconColor: string;
  inputBoxTextBox: string;
  inputBoxHelpMessage: string;
  inputBoxHelpMessageColor: string;
  inputBoxCanCloseFunction: any;
  inputBoxTextMaxLength: number;

  decisionBoxTitleText: string;
  decisionBoxBodyText: string;
  decisionBoxBodyIcon: string;
  decisionBoxIconColor: string;
  decisionBoxCanCloseFunction: any;

  userLevelWarningDialogTitleText: string;
  userLevelWarningDialogBodyText: string;
  userLevelWarningDialogBodyIcon: string;
  userLevelWarningDialogIconColor: string;
  userLevelWarningDialogCode: string;

  isInputBoxVisible: boolean;
  isDecisionBoxVisible: boolean;
  isUserLevelWarningDialogVisible: boolean;
  isOverlayVisible: boolean;
  isFadingOn: boolean;
  isDialogBlocked: boolean;
  isDialogRefreshAlloed: boolean;
  doesDialogHaveError: boolean;
  dialogErrorObject: any;
  initialAddFieldXPos: number;
  initialAddFieldYPos: number;
  alertWidthHeightCss: string;
  decisionWidthHeightCss: string;
  inputWidthHeightCss: string;
  userLevelWarningDialogWidthHeightCss: string;
}

export interface IRootState {
  dialogState: IDialogState;
  editorState: IEditorState;
  propertyGridState: IPropertyGridState;
  companyState: ICompanyState;
}

// Define the shape of actions
export interface Action {
  type: string;
  payload?: any;
}

// Define the context value
export interface StoreContextValue {
  state: IRootState;
  dispatch: ReactDispatch<Action>;
}

// Define the props for the StoreProvider component
export interface StoreProviderProps {
  reducer: React.Reducer<IRootState, Action>;
  initialState: IRootState;
  children: React.ReactNode;
}

// Store context
export const StoreContext = createContext<{
  state?: IRootState;
  dispatch: Dispatch<Action>;
}>({
  dispatch: () => {},
});

// Define a selector type
export type StoreSelector<T> = (state: IRootState) => T;
